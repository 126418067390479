import { ArrowButton } from '../../../components/arrow-button/arrow-button'

import CSS from './title-block.module.css'

export const TitleBlock = () => {
    return (
        <div className={CSS.layout}>

            <section className={CSS.details}>
                <div className="bt-2 bc-white my-sm maxw-10"></div>
				{/* <p className="fs-4 fw-600 mt-sm caps">Maximising airport real estate </p> */}
				<p className="fs-4 fw-500 mt-xs">19 June 2024</p>
				<p className="fs-5 mt-xs">Movenpick Hotel, Windhoek, Namibia</p>
				<p className="mt-sm">
					<ArrowButton href={`https://www.eventbooking.uk.com/aviadev-2024/`} solid theme={{ arrow: `white`, border: `var(--accent)`, label: `white`}} >REGISTER NOW</ArrowButton>
				</p>
            </section>

            <section className={`${CSS.themeHeading} ff-outfit`}>
                <p>Enabling<br/>Transformation</p>
                <p>Sustaining <br/>Progress</p>
            </section>

        </div>
    )
}