import Image from 'next/image'
import Link from 'next/link'
         
import { IconButton } from '../../../../components/icon-button/icon-button'

import CSS from './content-card.module.css'

export const ContentCard = ({ url, heroUrl, heading, excerpt, contentTypes, postDate, type, inverse }) => {

    return (
        <div className={CSS.layout}>

            <Link href={url}>
                <a className={CSS.hero}>
                    {/* <span className={CSS.overlay}>
                        <Image src={`/assets/theme/2023/re-content-library-card-overlay-2023.png`} layout='fill' alt="" />
                    </span> */}
                    {
                        heroUrl ?
                        <Image src={ heroUrl } layout='fill' alt="" />
                        : 
                        <Image src={`/assets/ui/placeholder-card.png`} layout='fill' alt="" />
                    // }
                    }
                </a>
            </Link>

            <div className={`${CSS.info} bl-1 ${inverse ? 'bc-white' : 'bc-secondary'}`}>

                <Link href={url}><a className={`${CSS.titleLink} fs-5 fw-500 ${inverse ? `c-white` : `c-secondary`} lh-2 mt-xxs`}>{ heading }</a></Link>

                { 
                    excerpt &&
                    <p className='mt-xxs'>{ excerpt }</p>
                }

                <p className='caps fs-sm fw-500 mt-xs'>
                    <IconButton href={url} type={type} inverse={inverse} />
                </p>
            </div>

        </div>
    )
}