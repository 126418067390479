import { gql } from "@apollo/client"
import craftApolloClient from "./api/apollo"

import Head from 'next/head'
import Image from "next/image"

import handleSeomaticMeta from "../lib/seomatic"

import { 
    QueryPageHeader,
	QueryText, 
    QueryFlipFlops, 
    QueryFloaters, 
    QueryBillboards, 
    QueryBillboardSliders, 
    QueryImpactFigures, 
    QueryVideos,
    QueryForm,
    QueryProfileCarousel,
    QuerySEO,
    QueryImages,
	QueryOffsetCallout,
	QueryCards,
	QuerySpacer,
	BizzaboSpeakers,
	BizzaboPartners
} from "../graphql/queries"

import { GetBizzaboPartners } from "../lib/bizzabo-partners"

import { HeaderShim } from '../fuselage/global/header/header'
import { TitleBlock } from '../fuselage/theme/2024/layout/title-block'

import { 
    RadarUnit, 
    RadarQuadrant, 
    RadarQuadrentCircle, 
    RadarPulse, 
    RadarGrid, 
    CenterCircle, 
    PulseLarge, 
    PulseSmall 
} from '../fuselage/theme/2024/radar-graphics/radar-graphics'
import { SectionCorner } from '../fuselage/theme/2024/section-corners/section-corners'
import { RadarDivider } from "../fuselage/theme/2024/radar-divider/radar-divider"


import { Quote } from '../fuselage/components/quote/quote'
import { FeatureCard } from '../fuselage/theme/2023/layout/homepage/features'
import { ContentCard } from '../fuselage/theme/2024/components/content-card/content-card'

import CSS from '../fuselage/theme/2024/homepage.module.css'

export default function Page ({ entry, podcasts, partnerData }) {

    return (
        <div style={{ width: `100%`, overflow: `hidden` }}>
            <Head>
				{ handleSeomaticMeta( entry.seomatic ) }
			</Head>

            <HeaderShim />

            <section style={{ width: `100%`, backgroundColor: `var(--primary)`, minHeight: `100vh`, overflow: `hidden`,  }}>
                <RadarGrid columns={3}>

                    <RadarUnit>
                        {/* 45 | 135 | 225 | 315 */}

                        <RadarQuadrant rotate={45} color={`var(--primary)`}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                        </RadarQuadrant>

                        <RadarQuadrant rotate={135} color={`var(--radar-orange)`} />

                        <RadarQuadrant rotate={225} color={`var(--radar-orange-tint-light)`} square={true}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                        </RadarQuadrant>

                        <RadarQuadrant rotate={315} color={`var(--radar-orange)`} />

                        {/* global elements */}
                        <RadarPulse width={4} />
                        <PulseSmall />
                    </RadarUnit>

                    <RadarUnit>
                        <RadarQuadrant rotate={225} color={`var(--radar-teal)`}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                            <PulseLarge />
                        </RadarQuadrant>

                        <RadarQuadrant rotate={135} color={`orange`} />

                        <RadarQuadrant rotate={45} color={`var(--radar-orange-tint-light)`}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                        </RadarQuadrant>

                        <RadarQuadrant rotate={315} color={`orange`} />
                    </RadarUnit>
                    
                    <RadarUnit>
                        <RadarQuadrant rotate={135} color={`var(--radar-teal)`}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                            <PulseLarge />
                        </RadarQuadrant>

                        <RadarQuadrant rotate={315} color={`var(--radar-orange-tint-light)`}>
                            <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                            <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                        </RadarQuadrant>

                        {/* global elements */}
                        <CenterCircle />
                        <PulseSmall />
                    </RadarUnit>
                    
                </RadarGrid>

                <TitleBlock />
            </section>


            <RadarDivider right>
                <RadarUnit>
                    <RadarQuadrant rotate={135} color={`var(--radar-orange-tint-light)`}>
                        <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                    </RadarQuadrant>

                    <RadarQuadrant rotate={225} color={`var(--radar-teal)`} mobileHide >
                        <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                        <PulseLarge />
                    </RadarQuadrant>

                    {/* global elements */}
                    <CenterCircle />
                    <PulseSmall />
                </RadarUnit>
            </RadarDivider>


            <section className='relative bg-dark pt-0'>
                {/* <SectionCorner backgroundColor={`var(--dark)`}/> */}
                <SectionCorner backgroundColor={`var(--primary)`} position={`right`}/>

                <div className={CSS.heroImage}>
                    <Image src={`/assets/img/2024/homepage-hero-02.jpg`} layout={`responsive`} width={1800} height={900} alt="" />
                </div>
            </section>

            <section className="block bg-dark pt-0">
                <div className="container pt-md pb-md">
                    <div className="maxw-65">
                        <Quote 
                            avatar={{ url: `https://cms.thebench.com/assets/img/avatars/Marcel.jpg`, alt: `Roger Foster`}}
                            quote={`Almost 90% of airport revenues are passenger related and therefore highly sensitive to external shocks and uncertainty in terms of recovery from the COVID-19 crisis. Focusing on non passenger revenues such as commercial real estate, can counterbalance these effects to some extent while increasing airport resilience and profitability.`} 
                            name={`Marcel Langeslag`} 
                            title={`Director Aviation Africa, NACO`}
                            decal={`c-white`}
                        />
                    </div>
                </div>
            </section>


            <RadarDivider>
                <RadarUnit>
                    <RadarQuadrant rotate={315} color={`var(--radar-orange-tint-light)`}>
                        <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                    </RadarQuadrant>

                    {/* global elements */}
                    <PulseSmall />
                </RadarUnit>
            </RadarDivider>

            
            <section className='block relative bg-secondary'>
                <SectionCorner backgroundColor={`var(--secondary)`}/>
                <SectionCorner backgroundColor={`var(--dark)`} position={`right`}/>

                <div className="container pt-md">
                    <p className={`fs-0 lh-1 c-primary caps`}>Who should attend?</p>
                </div>

                <div className="container pb-md">
                    <div className="columns-4 gap-md">
                        <FeatureCard heading={`Airports`} iconUrl={`/assets/img/2024/icons/icon-airports.svg`} iconColor={`red`}>
                            <p>Present your real estate projects to investors through pre-arranged one to one meetings.   Gain insights from investors, industry leaders and other airports</p>
                        </FeatureCard>

                        <FeatureCard heading={`Investors, Finance Institutions, Real Estate Developers, Hotel Operators`} iconUrl={`/assets/img/2024/icons/icon-investment.svg`} iconColor={`red`}>
                            <p>Meet with Airport operators with investable real-estate projects.  Discuss the development of their airport and surrounding land.</p>
                        </FeatureCard>

                        <FeatureCard heading={`Consultants, Advisors, Architects`} iconUrl={`/assets/img/2024/icons/icon-advisors.svg`} iconColor={`red`}>
                            <p>Support airports, developers and investors to plan, design and deliver real estate projects.</p>
                        </FeatureCard>

                        <FeatureCard heading={`Service Operators`} iconUrl={`/assets/img/2024/icons/icon-suppliers.svg`} iconColor={`red`}>
                            <p>Engage with the airport directly to understand their masterplan - and the opportunities around cargo, retail outlets, office space etc.</p>
                        </FeatureCard>
                    </div>
                </div>
            </section>



            <RadarDivider right>
                <RadarUnit>
                    <RadarQuadrant rotate={135} color={`var(--radar-orange-tint-light)`}>
                        <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                    </RadarQuadrant>
                    
                    <RadarQuadrant rotate={225} color={`var(--radar-teal)`} mobileHide >
                        <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                    </RadarQuadrant>

                    {/* global elements */}
                    <RadarPulse width={4} />
                    <PulseSmall />
                </RadarUnit>
            </RadarDivider>


            
            <section className='block relative bg-primary'>
                <SectionCorner backgroundColor={`var(--secondary)`}/>
                <SectionCorner backgroundColor={`var(--primary)`} position={`right`}/>
                
                <div className="container pt-lg">
                    <p className={`fs-0 lh-1 caps`}>Latest Podcasts</p>
                </div>

                <div className="container columns-3 gap-md pb-lg">
                    {
                        podcasts.map( podcast => {
                            return (
                                <ContentCard
                                    key={podcast.id}
                                    url={`/content-library/${podcast.slug}`}
                                    heroUrl={podcast.contentLibraryHero && podcast.contentLibraryHero[0] ? podcast.contentLibraryHero[0].url : null}
                                    heading={podcast.title}
                                    type={`podcast`}
									inverse
                                />
                            )
                        })
                    }
                </div>
            </section>


            <RadarDivider>
                <RadarUnit>
                    <RadarQuadrant rotate={225} color={`var(--radar-orange-tint-light)`} mobileHide >
                        <RadarQuadrentCircle size={80} color={`var(--radar-orange-tint-mid)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-orange-tint-dark)`} layer={2} />
                    </RadarQuadrant>

                    <RadarQuadrant rotate={315} color={`var(--radar-teal)`}>
                        <RadarQuadrentCircle size={80} color={`var(--radar-green)`} layer={1} />
                        <RadarQuadrentCircle size={40} color={`var(--radar-green-dark)`} layer={2} />
                        <PulseLarge />
                    </RadarQuadrant>

                    {/* global elements */}
                    <CenterCircle />
                    <PulseSmall />
                </RadarUnit>
            </RadarDivider>

            
            <section className='block relative bg-default'>
                <SectionCorner backgroundColor={`var(--secondary)`}/>
                <SectionCorner backgroundColor={`white`} position={`right`}/>

                <div className="container py-lg">
                <p className={`fs-0 lh-1 caps c-primary center`}>2023 Sponsors</p>
				{ GetBizzaboPartners ( partnerData, 1, "", "" ) }
                </div>
            </section>

        </div>
    )
}

Page.theme = 're-2024'




export async function getStaticProps({ preview, previewData }) {

    const data = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Homepage {
				entry(site: "${process.env.SITE_HANDLE}", id: "86450") {
					id
					title
					... on ${process.env.SITE_HANDLE}Homepage_${process.env.SITE_HANDLE}Homepage_Entry {
						${process.env.SITE_HANDLE}ContentBlocks {
							${QueryPageHeader}
							${QueryText} 
							${QueryFlipFlops} 
							${QueryFloaters} 
							${QueryBillboards} 
							${QueryBillboardSliders} 
							${QueryImpactFigures} 
							${QueryVideos}
							${QueryForm}
							${QueryProfileCarousel}
							${QuerySEO}
							${QueryImages}
							${QueryOffsetCallout}
							${QueryCards}
							${QuerySpacer}
							${BizzaboSpeakers}
							${BizzaboPartners}
						}
						${QuerySEO}
					}
				}
			}
        `
    })
    const pageData = data

	// pull podcast data from aviadev

	const podcastQuery = await craftApolloClient( preview, previewData ).query({
        query: gql`
			query Podcasts {
				entries(site: "aviadev", section: "contentLibrary", id: ["92705", "79308", "92708"]) {
					... on contentLibrary_article_Entry {
						id
						slug
						title
						postDate
						excerpt
						contentLibraryHero {
							id
							url
							width
							height
						}
						contentTypes {
							... on contentLibrary_Category {
								id
								title
								slug
							}
						}
					}
				}
			}
        `
    })
    const podcastData = podcastQuery


	// partners

	const partners = await fetch(`https://api.bizzabo.com/api/partners?eventId=${process.env.BIZZABO_EVENT_ID}&size=200`, {
		"method": "GET",
		"headers": {
			"Content-Type": "application/json",
			"Authorization": "Bearer 67f61f9e-e897-4536-914e-3f47286650f9"
		}
	})
	const partnerData = await partners.json()



    return { 
		props: { 
			entry: pageData.data.entry,
			podcasts: podcastData.data.entries,
			partnerData: partnerData.content,
		}
	}
}