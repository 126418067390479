import CSS from './radar-divider.module.css'

export const RadarDivider = ({ children, right }) => {
    return (
        <div className={CSS.positioner}>
            <div className={`${CSS.layout} ${right && CSS.right}`}>
                {children }
            </div>
        </div>
    )
}


