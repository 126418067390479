import Image from 'next/image'

import CSS from './features.module.css'

export const Features = ({ background, heading, children }) => {
    return (
        <section className={`${CSS.layout} ${background ? background : 'bg-default'}`}>


            <div className={CSS.heading}>
                <p className={`fs-0 lh-1 caps`}>{ heading }</p>
            </div>


            <div className={`${CSS.content} `}>
                { children }
            </div>

            <div className={CSS.graphic}></div>


        </section>
    )
}


export const FeatureCard = ({ iconUrl, iconColor, heading, children}) => {
	return (
		<section className={`formatted fw-400`}>
			<p><Image className={CSS.icon} src={iconUrl} width={100} height={100} alt="" /></p>
			<p className={`fs-4 fw-600 c-primary lh-2`}>{ heading }</p>
			{ children }
		</section>
	)
}
