import CSS from './radar-graphics.module.css'

export const RadarUnit = ({ children }) => {
    return (
        <div className={CSS.circleContainer}>
            { children }
        </div>
    )
}

export const RadarQuadrant = ({ children, rotate, square, color = `var(--accent)`, mobileHide }) => {
    return (
        <div 
            className={`
                ${CSS.quadrant}
                ${mobileHide && CSS.mobileHide}
            `}
            style={{
                transform: `rotate(${rotate}deg)`,
                backgroundColor: color,
                borderTopLeftRadius: !square && `100%`
            }}
        >
            { children }
        </div>
    )
}

export const RadarQuadrentCircle = ({ size, color, layer }) => {
    return (
        <div 
            style={{ 
                width: `${size/2 * 4}%`, 
                height: `${size/2 * 4}%`, 
                borderRadius: `50%`, 
                backgroundColor: color, 
                position: `absolute`, 
                zIndex: layer,
                bottom: `-${size/4 * 4}%`, 
                right: `-${size/4 * 4}%`}}
        />
    )
}

export const RadarPulse = ({ width = 4, color = `rgb(255, 72, 0)` }) => {
    return (
        <div 
            className={CSS.pulse}
            style={{
                borderWidth: `${width}vw`,
                borderColor: color,
            }}
        />
    )
}

export const CenterCircle = ({ color = `red`, opacity = .7 }) => {
    return (
        <div className={CSS.centerCircle} style={{ backgroundColor: color, opacity: opacity }}/>
    )
}

export const PulseLarge = () => {
    return (
        <div className={CSS.pulseLarge} />
    )
}

export const PulseSmall = () => {
    return (
        <div className={CSS.pulseSmall} />
    )
}



export const RadarGrid = ({ children, columns }) => {
    return (
        <div
            className={CSS.layoutGrid}
            style={{ gridTemplateColumns: `repeat(${columns},  ${ 100/columns }%)` }}
        >
            { children }
        </div>
    )
}

export const RadarGridPageHeader = ({ children, columns }) => {
    return (
        <div
            className={CSS.layoutGridPageHeader}
            style={{ gridTemplateColumns: `repeat(${columns},  ${ 100/columns }%)` }}
        >
            { children }
        </div>
    )
}